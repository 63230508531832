<template>
  <div>
    <search
      :element-data="elementData"
      :arl-list="selectorList.arl"
      :service-type-list="selectorList.serviceType"
      :auth-status-list="selectorList.authStatus"
      :resources-element-name="RESOURCES_ELEMENT_NAME" />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
        </b-row>
      </div>
      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refElementsListTable"
          class="position-relative"
          :items="itemsFormatted"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
          <template #cell(services)="data">
            {{ getServices(data.item) }}
            <b-button
              :id="`see-services-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              @click="seeServices(data.item.services)">
              <feather-icon icon="EyeIcon" />
              <!-- ? SEE SERVICES -->
              <b-tooltip
                :target="`see-services-${data.item.id}`"
                title="Ver servicios"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-button>
          </template>

          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col>
              <b-button
                :id="`edit-button-${data.item.id}`"
                style="margin: 1px"
                variant="primary"
                :to="{ name: 'arl-codes-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
              </b-button>

              <!-- ? EDIT -->
              <b-tooltip
                :target="`edit-button-${data.item.id}`"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
      </vue-double-scrollbar>

      <div>
        <b-modal
          id="modal-service-detail"
          :title="$t('arl_codes' + '.object.view_services')"
          hide-footer
          size="lg">
          <template>
            <service-detail-tab :element-data="currentGroupData" />
          </template>
        </b-modal>
      </div>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-if="tableDataLoaded"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BModal,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// Remove the circular dependency
// import router from '@/router'
import Search from '@/views/arl_codes/search/Search.vue'
import casesStoreModule from '@/views/case/received/storeModule'
import moment from 'moment'
// import dataList from '@/store/data-list'
import { mapState } from 'vuex'
import SessionStorage from '@/handlers/SessionStorage'
import ElementStoreModule from './storeModule'
import ServiceDetailTab from '../modal/ServiceDetail.vue'

export default {
  components: {
    ServiceDetailTab,
    Search,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BModal,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      CASES_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      itemsFormatted: [],
      total: 1,
      perPage: 5,
      currentPage: this.currentPage,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {},
      selectorList: {},
      tableDataLoaded: false,
      currentGroupData: {},
    }
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
    ...mapState('dataList', ['getDataList']),
    getState() {
      return this.$store.state.dataList.getArlCodesList
    },
  },
  watch: {
    elementData: {
      handler(val) {
        this.fetchElements()
      },
      deep: true,
    },
    currentPage(val) {
      this.fetchElements()
    },
    perPage(val) {
      this.fetchElements()
    },
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'arl_codes'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-search-${this.RESOURCES_ELEMENT_NAME}`
    this.CASES_APP_STORE_MODULE_NAME = 'app-case-app-'
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    // Register cases store module
    if (!store.hasModule(this.CASES_APP_STORE_MODULE_NAME)) {
      store.registerModule(this.CASES_APP_STORE_MODULE_NAME, casesStoreModule)
      // store.registerModule(this.CASES_APP_STORE_MODULE_NAME, ListStoreModule)
    }

    this.search_storage = new SessionStorage('avenir')
    if (this.$route.path === '/arl_codes/search') {
      this.field_date = 'proposed_date'
      this.tableColumns = [
        {
          key: 'id',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
          sortable: true,
        },
        {
          key: 'scheduled_date',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.scheduled_date`
          ),
          sortable: true,
        },
        {
          key: 'case_number',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.case_number`),
          sortable: true,
        },
        {
          key: 'arl_name',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl`),
          sortable: true,
        },
        {
          key: 'patient_identity',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.patient_identity`
          ),
          sortable: true,
        },
        {
          key: 'patient_name',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`),
          sortable: true,
        },
        {
          key: 'service_type_value',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.service_type`),
          sortable: true,
        },
        {
          key: 'follow_type_name',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.follow_type`),
          sortable: true,
        },
        {
          key: 'requires_freezer_value',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.requires_freezer`
          ),
          sortable: true,
        },
        {
          key: 'medical_order_status_value',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.medical_order_status`
          ),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions.tittle'),
          thStyle: 'width: 300px;',
        },
      ]
    } else if (this.$route.path === '/arl_packages/search') {
      this.field_date = 'authorization_date'
      this.tableColumns = [
        {
          key: 'patient_identity',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.patient_identity`
          ),
          sortable: true,
        },
        {
          key: 'patient_name',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`),
          sortable: true,
        },
        {
          key: 'number',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
          sortable: true,
        },
        {
          key: 'bundle_name',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.bundle_name`),
          sortable: true,
        },
        {
          key: 'group',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.group`),
          sortable: true,
        },
        {
          key: 'services',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.services`),
          sortable: true,
        },
        {
          key: 'authorization_date',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.authorization_date`
          ),
          sortable: true,
        },
        {
          key: 'vigence_date',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.vigence_date`),
          sortable: true,
        },
        {
          key: 'arl_code',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl_code`),
          sortable: true,
        },
        {
          key: 'authorizated_by',
          label: this.$t(
            `${this.RESOURCES_ELEMENT_NAME}.object.authorizated_by`
          ),
          sortable: true,
        },
        {
          key: 'observations',
          label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.observations`),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions.tittle'),
          thStyle: 'width: 300px;',
        },
      ]
    } else {
      this.tableColumns = null
    }

    this.loadSearch()
    this.fetchElements()
    const dataSelect = this.$store.getters['dataList/getDataList']({
      listState: ['arl', 'serviceType', 'authStatus'],
      messageDefault: 'SELECT_ALL',
    })

    this.selectorList = {
      arl: dataSelect.arl,
      serviceType: dataSelect.serviceType,
      authStatus: dataSelect.authStatus,
    }
  },
  unmounted() {
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    if (store.hasModule(this.CASES_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.CASES_APP_STORE_MODULE_NAME)
  },
  methods: {
    is_source_positive(element) {
      return element.source_positive
    },
    is_patient_positive(element) {
      return element.patient_positive
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i += 1) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    seeServices(group) {
      this.currentGroupData = group
      this.$root.$emit('bv::show::modal', 'modal-service-detail')
    },
    getServices(element) {
      let ret = ''
      ret += element.services
        .map((service) => `${service.cups || service.cups_name} `)
        .join('')
      return ret
    },
    loadSearch() {
      this.elementData = this.search_storage.getValueStorage()
      this.currentPage = this.elementData.page || 1
      this.perPage = this.elementData.perPage || 5
      if (!Object.keys(this.elementData).length) {
        this.elementData = {
          arl_id: null,
          service_type_id: null,
          auth_status_id: null,
        }
      }
    },
    fetchElements() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
            medical_order_number: this.elementData.medical_order_number,
            auth_code: this.elementData.auth_code,
            field_date: this.field_date,
            from: this.elementData.from,
            until: this.elementData.until,
            patient_identity: this.elementData.patient_identity,
            patient_name: this.elementData.patient_name,
            arl_id: this.elementData.arl_id,
            service_type_id: this.elementData.service_type_id,
            auth_status_id: this.elementData.auth_status_id,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          this.itemsFormatted = this.items.map((item) => ({
            ...item,
            scheduled_date: this.formatDate(item.scheduled_date),
            authorization_date: this.formatDate(item.authorization_date),
            vigence_date: this.formatDate(item.vigence_date),
          }))
          this.tableDataLoaded = true
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
    formatDate(data) {
      let ret = moment(data, 'DD/MM/YYYY').format('DD/MM/YYYY')
      if (ret === 'Invalid date' || ret === 'Fecha inválida') {
        ret = moment(data).format('DD/MM/YYYY')
      }
      return ret === 'Invalid date' || ret === 'Fecha inválida' ? '' : ret
    },
    // async fetch_selector() {
    //   return store
    //     .dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/fetch_selector`, {})
    //     .then((response) => {
    //       return response.data
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 404) {
    //         return undefined
    //       }
    //     })
    // },
    // async fetch_key_value(type) {
    //   return store
    //     .dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/fetch_key_value`, {
    //       type,
    //     })
    //     .then((response) => {
    //       const list = response.data.items
    //       list.unshift({
    //         id: null,
    //         value: this.$t(
    //           `${this.RESOURCES_ELEMENT_NAME}.search.defaultOption`
    //         ),
    //       })
    //       return list
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 404) {
    //         return undefined
    //       }
    //     })
    // },
    // async fetchElement() {
    //   return store
    //     .dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/fetch_element`, {
    //       id: router.currentRoute.params.id,
    //     })
    //     .then((response) => {
    //       this.elementData = response.data
    //       if (this.elementData.logo_id) {
    //         this.fetchLogo(this.elementData.logo_id)
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 404) {
    //         this.elementData = undefined
    //       }
    //     })
    // },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dot_red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot_purple {
  height: 25px;
  width: 25px;
  background-color: purple;
  border-radius: 50%;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
